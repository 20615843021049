import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import Header from '../partials/Header';
import axios from 'axios';
import { toast } from 'react-toastify';
import PageIllustration from '../partials/PageIllustration';
import Footer from '../partials/Footer';

function Contact() {
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  const [telephone, setTelephone] = useState('');
  const [message, setMessage] = useState('');
  const [foundUs, setFoundUs] = useState('');
  const [loadSpinner, setLoadSpinner] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      setLoadSpinner(true);
      const data = await axios({
        method: 'post',
        // headers: {
        //   'Content-Type': 'application/json',
        //   'Access-Control-Allow-Origin': '*',
        // },
        url: 'https://apmcnisiinitiatives.herokuapp.com/api/contact',
        data: {
          firstname,
          lastname,
          state,
          email,
          telephone,
          message,
          referral: foundUs,
        },
      });
      console.log(data)
      toast('Message sent Successfully');
      setLoadSpinner(false);
    } catch (err) {
      if (err.response) {
        toast(`${err.response.data}`);
        setLoadSpinner(false);
      } else {
        toast(`${err.message}`);
        setLoadSpinner(false);
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <h1 className="h1 font-red-hat-display mb-4">Contact Us</h1>
                <p className="text-xl text-gray-600 dark:text-gray-400">
                  Send us a message and let's know how we can help you.
                </p>
              </div>

              {/* Contact form */}
              <form className="max-w-xl mx-auto">
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                    <label
                      className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                      htmlFor="first-name"
                    >
                      First Name <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="first-name"
                      type="text"
                      className="form-input w-full"
                      placeholder="Enter your first name"
                      required
                      name="firstname"
                      value={firstname}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                      htmlFor="last-name"
                    >
                      Last Name <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="last-name"
                      type="text"
                      className="form-input w-full"
                      placeholder="Enter your last name"
                      required
                      name="lastname"
                      value={lastname}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <label
                      className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                      htmlFor="email-address"
                    >
                      Email Address <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="company"
                      type="text"
                      className="form-input w-full"
                      placeholder="Enter your email address"
                      required
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <label
                      className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                      htmlFor="phone"
                    >
                      Phone Number <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="phone"
                      type="tel"
                      className="form-input w-full"
                      placeholder="Enter your phone number"
                      required
                      name="telephone"
                      value={telephone}
                      onChange={(e) => setTelephone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <label
                      className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                      htmlFor="country"
                    >
                      State <span className="text-red-600">*</span>
                    </label>
                    <select
                      id="country"
                      className="form-select w-full"
                      required
                      name="state"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    >
                      <option>Abia</option>
                      <option>Adamawa</option>
                      <option>Akwa Ibom</option>
                      <option>Anambra</option>
                      <option>Bauchi</option>
                      <option>Bayelsa</option>
                      <option>Benue</option>
                      <option>Borno</option>
                      <option>Cross River</option>
                      <option>Delta</option>
                      <option>Ebonyi</option>
                      <option>Edo</option>
                      <option>Ekiti</option>
                      <option>Enugu</option>
                      <option>Gombe</option>
                      <option>Imo</option>
                      <option>Jigawa</option>
                      <option>Kaduna</option>
                      <option>Kano</option>
                      <option>Katsina</option>
                      <option>Kebbi</option>
                      <option>Kogi</option>
                      <option>Kwara</option>
                      <option>Lagos</option>
                      <option>Nassarawa</option>
                      <option>Niger</option>
                      <option>Ogun</option>
                      <option>Ondo</option>
                      <option>Osun</option>
                      <option>Oyo</option>
                      <option>Plateau</option>
                      <option>Rivers</option>
                      <option>Sokoto</option>
                      <option>Taraba</option>
                      <option>Yobe</option>
                      <option>Zamfara</option>
                      <option>Federal Capital Territory - Abuja</option>
                    </select>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <div className="flex justify-between items-center mb-1">
                      <label
                        className="block text-gray-800 dark:text-gray-300 text-sm font-medium"
                        htmlFor="message"
                      >
                        Details
                      </label>
                      <span className="text-sm text-gray-500">Optional</span>
                    </div>
                    <textarea
                      id="message"
                      rows="4"
                      className="form-textarea w-full"
                      placeholder="What enquiry would like to make?"
                      required
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <div className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-3">
                      Tell us how you found us?
                    </div>
                    <label className="flex items-center mb-2">
                      <input
                        type="radio"
                        className="form-radio"
                        name="role"
                        value={foundUs}
                        onChange={(e) => setFoundUs('friends')}
                      />
                      <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">
                        Friends
                      </span>
                    </label>
                    <label className="flex items-center mb-2">
                      <input
                        type="radio"
                        className="form-radio"
                        name="role"
                        value={foundUs}
                        onChange={(e) => setFoundUs('flyers')}
                      />
                      <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">
                        Flyers
                      </span>
                    </label>
                    <label className="flex items-center mb-2">
                      <input
                        type="radio"
                        className="form-radio"
                        name="role"
                        value={foundUs}
                        onChange={(e) => setFoundUs('magazine')}
                      />
                      <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">
                        Magazine
                      </span>
                    </label>
                    <label className="flex items-center mb-2">
                      <input
                        type="radio"
                        className="form-radio"
                        name="role"
                        value={foundUs}
                        onChange={(e) => setFoundUs('newspaper')}
                      />
                      <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">
                        Newspaper
                      </span>
                    </label>
                    <label className="flex items-center mb-2">
                      <input
                        type="radio"
                        className="form-radio"
                        name="role"
                        value={foundUs}
                        onChange={(e) => setFoundUs('blog')}
                      />
                      <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">
                        Blog
                      </span>
                    </label>
                    <label className="flex items-center mb-2">
                      <input
                        type="radio"
                        className="form-radio"
                        name="role"
                        value={foundUs}
                        onChange={(e) => setFoundUs('others')}
                      />
                      <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">
                        Other
                      </span>
                    </label>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <button
                      className="btn text-white bg-teal-500 hover:bg-teal-400 w-full flex items-center"
                      onClick={submitForm}
                    >
                      {loadSpinner ? (
                        <Loader
                          type="TailSpin"
                          color="#FFFFFF"
                          height={20}
                          width={20}
                          timeout={3000} //3 secs
                        />
                      ) : (
                        ''
                      )}
                      <span>Send</span>
                      <svg
                        className="w-3 h-3 flex-shrink-0 mt-px ml-2"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="fill-current"
                          d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Contact;
