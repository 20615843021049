import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'react-loader-spinner';
// import  { CInputGroup, CInputGroupText, CFormGroup } from '@coreui/coreui';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Calculator() {
  const [gasolineExchange, setGasolineExchange] = useState('');
  const [gasolinePrice, setGasolinePrice] = useState('');
  const [gasoilExchange, setGasoilExchange] = useState('');
  const [gasoilPrice, setGasoilPrice] = useState('');
  const [dateDisplay, setDateDispaly] = useState('');
  const [calculatedGasoline, setCalculatedGasoline] = useState('');
  const [calculatedGasoil, setCalculatedGasoil] = useState('');

  // const [password, setPassword] = useState('');
  const [loadSpinner, setLoadSpinner] = useState(false);

  //used for routing to dashboard after token retured
  useEffect(() => {
    const fetchData = async () => {
      const data = await axios({
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
        url: 'https://apmcnisiinitiatives.herokuapp.com/api/calculator',
      }).then((res) => res.data);
      data.calculators.reverse();
      setGasolinePrice(data.calculators[0].gasolinePrice);
      setGasoilPrice(data.calculators[0].gasoilPrice);
      setDateDispaly(data.calculators[0].date);

      // setStatePricesData(newData);
    };
    fetchData();
  }, []);

  const calculate1 = () => {
    let final =
      ((Number(gasolinePrice) + 40) / 1341) * Number(gasolineExchange) + 16;
    setCalculatedGasoline(final.toFixed(2));
    setLoadSpinner(false);
  };
  const calculate2 = () => {
    let final = ((Number(gasoilPrice) + 40) / 1164) * Number(gasoilExchange);
    setCalculatedGasoil(final.toFixed(2));
    setLoadSpinner(false);
  };

  return (
    <section className="relative">
      {/* <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
    <div className="pt-32 pb-12 xxmd:pt-40 md:pb-20 py-12 md:py-20 border-t border-gray-200 dark:border-gray-800"> */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20 py-12 md:py-20 border-t border-gray-200 dark:border-gray-800">
          {/* <div className="lg:flex lg:justify-between"> */}
          {/* <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20"> */}
          {/* Page header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-6">
            <h1 className="h1 font-red-hat-display mb-4">Calculator</h1>
            <p className="text-xl text-gray-600 dark:text-gray-400">
              Gasoline CIF WAF for {dateDisplay}.
            </p>
          </div>

          <div
            className="mt-8xx max-w-md mx-auto mb-6"
            data-aos="fade-down"
            data-aos-delay="300"
          >
            <button className="flex flex-col sm:flex-row justify-center max-w-sm mx-auto sm:max-w-md md:mx-0">
              {/* Exchange Rate */}
              <a
                style={{
                  borderBottomRightRadius: '0px',
                  borderTopRightRadius: '0px',
                  backgroundColor: '#7c7d7c',
                }}
                className="btn text-white bg-teal-500 xxhover:bg-teal-400 flex-shrink-0"
                href="#0"
              >
                ₦
              </a>
              <input
                style={{
                  borderBottomLeftRadius: '0px',
                  borderTopLeftRadius: '0px',
                }}
                type="number"
                className="form-input w-fullxx mb-2 sm:mb-0 sm:mr-2"
                placeholder="Exchange Rate"
                aria-label="Exchange Rate"
                value={gasolineExchange}
                onChange={(e) => setGasolineExchange(e.target.value)}
                required
              />

              {/* Price Per Metric Ton */}
              <a
                href="#0"
                style={{
                  borderBottomRightRadius: '0px',
                  borderTopRightRadius: '0px',
                  backgroundColor: '#7c7d7c',
                }}
                className="btn text-white bg-teal-500 xxhover:bg-teal-400 flex-shrink-0"
              >
                $
              </a>
              <input
                style={{
                  borderBottomLeftRadius: '0px',
                  borderTopLeftRadius: '0px',
                }}
                type="text"
                hasIcon="left"
                className="form-input w-fullxx mb-2 sm:mb-0 sm:mr-2"
                placeholder="Price Per Metric Ton"
                aria-label="Price Per Metric Ton"
                disabled
                value={gasolinePrice}
                onChange={(e) => setGasolinePrice(e.target.value)}
                required
              />

              {/* Action Button with Loader - TailSpin effect */}
              <a
                className="w-fullxx btn text-white bg-teal-500 hover:bg-teal-400 flex-shrink-0 mb-2 sm:mb-0 sm:mr-2"
                href="#0"
                onClick={calculate1}
              >
                {loadSpinner ? (
                  <Loader
                    type="TailSpin"
                    color="#FFFFFF"
                    height={20}
                    width={20}
                    timeout={3000} //3 secs
                  />
                ) : (
                  ''
                )}
                Calculate
                <svg
                  className="w-3 h-3 flex-shrink-0 mt-px ml-2"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-current"
                    d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z"
                  />
                </svg>
              </a>

              {/* Price Per Litre */}
              <a
                href="#0"
                style={{
                  borderBottomRightRadius: '0px',
                  borderTopRightRadius: '0px',
                  backgroundColor: '#7c7d7c',
                }}
                className="btn text-white bg-teal-500 xxhover:bg-teal-400 flex-shrink-0"
              >
                ₦
              </a>
              <input
                style={{
                  borderBottomLeftRadius: '0px',
                  borderTopLeftRadius: '0px',
                }}
                type="number"
                className="form-input w-fullxx mb-2 sm:mb-0 sm:mr-2"
                placeholder="Price Per Litre"
                aria-label="Price Per Litre"
                value={calculatedGasoline}
                // onChange={(e) => setEmail(e.target.value)}
                required
                disabled
              />
            </button>
          </div>

          {/* Gasoil 0.3% FOB STS WAF */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-6">
            <p className="text-xl text-gray-600 dark:text-gray-400">
              Gasoil 0.3% FOB STS WAF for {dateDisplay}.
            </p>
          </div>
          <div
            className="mt-8xx max-w-md mx-auto"
            data-aos="fade-down"
            data-aos-delay="300"
          >
            <button className="flex flex-col sm:flex-row justify-center max-w-sm mx-auto sm:max-w-md md:mx-0">
              {/* Exchange Rate */}
              <a
                href="#0"
                style={{
                  borderBottomRightRadius: '0px',
                  borderTopRightRadius: '0px',
                  backgroundColor: '#7c7d7c',
                }}
                className="btn text-white bg-teal-500 xxhover:bg-teal-400 flex-shrink-0"
              >
                ₦
              </a>
              <input
                style={{
                  borderBottomLeftRadius: '0px',
                  borderTopLeftRadius: '0px',
                }}
                type="number"
                className="form-input w-fullxx mb-2 sm:mb-0 sm:mr-2"
                placeholder="Exchange Rate"
                aria-label="Exchange Rate"
                value={gasoilExchange}
                onChange={(e) => setGasoilExchange(e.target.value)}
                required
              />

              {/* Price Per Metric Ton */}
              <a
                href="#0"
                style={{
                  borderBottomRightRadius: '0px',
                  borderTopRightRadius: '0px',
                  backgroundColor: '#7c7d7c',
                }}
                className="btn text-white bg-teal-500 xxhover:bg-teal-400 flex-shrink-0"
              >
                $
              </a>
              <input
                style={{
                  borderBottomLeftRadius: '0px',
                  borderTopLeftRadius: '0px',
                }}
                type="text"
                className="form-input w-fullxx mb-2 sm:mb-0 sm:mr-2"
                placeholder="Price Per Metric Ton"
                aria-label="Price Per Metric Ton"
                disabled
                value={gasoilPrice}
                // onChange={(e) => setGasoilPrice(e.target.value)}
                required
              />

              {/* Action Button with Loader - TailSpin effect */}
              <a
                className="w-fullxx btn text-white bg-teal-500 hover:bg-teal-400 flex-shrink-0 mb-2 sm:mb-0 sm:mr-2"
                href="#0"
                onClick={calculate2}
              >
                {loadSpinner ? (
                  <Loader
                    type="TailSpin"
                    color="#FFFFFF"
                    height={20}
                    width={20}
                    timeout={3000} //3 secs
                  />
                ) : (
                  ''
                )}
                Calculate
                <svg
                  className="w-3 h-3 flex-shrink-0 mt-px ml-2"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-current"
                    d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z"
                  />
                </svg>
              </a>

              {/* Price Per Litre */}
              <a
                href="#0"
                style={{
                  borderBottomRightRadius: '0px',
                  borderTopRightRadius: '0px',
                  backgroundColor: '#7c7d7c',
                }}
                className="btn text-white bg-teal-500 xxhover:bg-teal-400 flex-shrink-0"
              >
                ₦
              </a>
              <input
                style={{
                  borderBottomLeftRadius: '0px',
                  borderTopLeftRadius: '0px',
                }}
                type="number"
                className="form-input w-fullxx mb-2 sm:mb-0 sm:mr-2"
                placeholder="Price Per Litre"
                aria-label="Price Per Litre"
                value={calculatedGasoil}
                // onChange={(e) => setEmail(e.target.value)}
                required
                disabled
              />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Calculator;
