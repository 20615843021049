import React, { useEffect } from 'react';
import ls from 'local-storage';
import { Switch, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import '../src/partials/FontAwesomeIcons';
import 'react-toastify/dist/ReactToastify.css';
import './css/style.scss';
import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';
import Home from './pages/Home';
// import Testimonials from './pages/Testimonials';
import Blog from './pages/Blog';
// import BlogPost from './pages/BlogPost';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Signin from './pages/Signin';
import Subscribe from './pages/Subscribe';
// import Help from './pages/Help';
import PageNotFound from './pages/PageNotFound';
import MyAccount from './pages/MyAccount';
import Dashboard from './pages/Dashboard';
import NigeriasPortHarcourtRefinery from './pages/NigeriasPortHarcourtRefinery';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import NigeriasBuhariRestoresOil from './pages/NigeriasBuhariRestoresOil';
import NigeriasLoosingCrudeOil from './pages/NigeriasLoosingCrudeOil';
import NigeriasOilCurseCouldBecomeAnOpportunity from './pages/NigeriasOilCurseCouldBecomeAnOpportunity';

function App() {
  const location = useLocation();
  let userDetails = ls.get('userDetails');

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 750,
      easing: 'ease-out-quart',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <ToastContainer />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        {/* <Route path="/testimonials">
          <Testimonials />
        </Route> */}
        <Route path="/blog">
          <Blog />
        </Route>
        {/* <Route path="/blog-post">
          <BlogPost />
        </Route> */}
        <Route path="/nigerias-portharcourt-refinery">
          <NigeriasPortHarcourtRefinery />
        </Route>
        <Route path="/nigerias-buhari-restores-oil">
          <NigeriasBuhariRestoresOil />
        </Route>
        <Route path="/nigeria-loosing-crudeoil">
          <NigeriasLoosingCrudeOil/>
        </Route>
        <Route path="/nigerias-oil-curse-could-become-an-opportunity-for-renewable-power">
          <NigeriasOilCurseCouldBecomeAnOpportunity/>
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/services">
          <Services />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/signin">
          <Signin />
        </Route>
        <Route path="/forgotpassword">
          <ForgotPassword />
        </Route>
        <Route path="/resetpassword">
          <ResetPassword />
        </Route>
        <Route path="/subscribe">
          <Subscribe />
        </Route>
        {userDetails !== null ? (
          <Route path="/dashboard" component={Dashboard} exact></Route>
        ) : (
          <Route path="/signin">
            <Signin />
          </Route>
        )}
        <Route path="/my-account">
          <MyAccount />
        </Route>
        {/* <Route path="/help">
          <Help />
        </Route> */}
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
}

export default App;
