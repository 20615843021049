import React from 'react';

import Header from '../partials/Header';
import HeroServices from '../partials/HeroServices';
// import Services from './Services/Services';
import FeaturesServices from '../partials/FeaturesServices';
// import Timeline from '../partials/Timeline';
// import Career from '../partials/Career';
// import FeaturesAnimation from '../partials/FeaturesAnimation';
// import Team from '../partials/Team';
import Cta from '../partials/Cta';
import Footer from '../partials/Footer';

function Services() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <HeroServices />
        {/* <Services/> */}
        <FeaturesServices />
        <Cta />
        {/* <Timeline />
        <Career />
        <FeaturesAnimation /> */}
        {/* <Team /> */}
        {/* <CtaContact /> */}

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Services;