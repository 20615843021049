import React from 'react';
import { Link } from 'react-router-dom';

function BlogList() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20 py-12 md:py-20xx border-t border-gray-200 dark:border-gray-800">
          <div className="lg:flex lg:justify-between">

            {/* Main content */}
            <div className="sm:flex-grow text-center" data-aos="fade-down" data-aos-delay="200">

              {/* Section title */}
              <h4 className="h3 font-red-hat-display mb-8 mt-8">Latest News</h4>

              {/* Articles container */}
              <div className="grid gap-6 sm:grid-cols-3 sm:gap-x-6 md:gap-y-8 items-start">

                {/* 7th article */}
                <article className="flex flex-col h-full mb-4">
                  <header>
                    <Link className="block mb-4" to={{pathname: "https://www.bloombergquint.com/gadfly/nigeria-s-oil-curse-could-become-an-opportunity-for-renewable-power"}} target="_blank">
                      <figure className="relative h-0 pb-9/16">
                        <img className="absolute inset-0 w-full h-full object-cover" src={require('../images/nigeria-s-oil-curse-could-become-an-opportunity-for-renewable-power.jpeg').default} width="352" height="198" alt="News 01" />
                      </figure>
                    </Link>
                    <Link className="hover:underline" to={{pathname: "https://www.bloombergquint.com/gadfly/nigeria-s-oil-curse-could-become-an-opportunity-for-renewable-power"}} target="_blank">
                      <h3 className="h5 font-red-hat-display mb-2"> <b>Nigeria’s Oil Curse Could Become an Opportunity</b> </h3>
                    </Link>
                  </header>
                  <p className="text-gray-600 dark:text-gray-400 flex-grow">Just a decade after crude was first discovered in 1957, it was already tearing apart the young country in civil war...</p>
                </article>

                {/* 6th article */}
                <article className="flex flex-col h-full mb-4">
                  <header>
                    <Link className="block mb-4" to={{pathname: "https://www.spglobal.com/platts/en/market-insights/latest-news/oil/022521-nigeria-losing-200000-bd-to-crude-oil-theft-as-sabotage-grows-nnpc"}} target="_blank">
                      <figure className="relative h-0 pb-9/16">
                        <img className="absolute inset-0 w-full h-full object-cover" src={require('../images/nigeria-losing-200,000.jpeg').default} width="352" height="198" alt="News 01" />
                      </figure>
                    </Link>
                    <Link className="hover:underline" to={{pathname: "https://www.spglobal.com/platts/en/market-insights/latest-news/oil/022521-nigeria-losing-200000-bd-to-crude-oil-theft-as-sabotage-grows-nnpc"}} target="_blank">
                      <h3 className="h5 font-red-hat-display mb-2"> <b>Nigeria losing 200,000 b/d to crude oil theft as sabotage grows: NNPC</b> </h3>
                    </Link>
                  </header>
                  <p className="text-gray-600 dark:text-gray-400 flex-grow">Lagos — Nigeria is losing an average of 200,000 b/d of its crude oil production to theft, state-owned Nigerian National...</p>
                </article>

                {/* 5th article */}
                <article className="flex flex-col h-full mb-4">
                  <header>
                    <Link className="block mb-4" to={{pathname: "https://www.bloomberg.com/news/articles/2021-04-23/nigeria-s-buhari-restores-oil-permits-revoked-from-china-s-addax"}} target="_blank">
                      <figure className="relative h-0 pb-9/16">
                        <img className="absolute inset-0 w-full h-full object-cover" src={require('../images/nigeria’s-buhari-restores-oil-permits.jpg').default} width="352" height="198" alt="News 01" />
                      </figure>
                    </Link>
                    <Link className="hover:underline" to={{pathname: "https://www.bloomberg.com/news/articles/2021-04-23/nigeria-s-buhari-restores-oil-permits-revoked-from-china-s-addax"}} target="_blank">
                      <h3 className="h5 font-red-hat-display mb-2"> <b>Nigeria’s Buhari Restores Oil Permits Revoked from China’s Addax</b> </h3>
                    </Link>
                  </header>
                  <p className="text-gray-600 dark:text-gray-400 flex-grow">Nigerian President Muhammadu Buhari reversed a government decision to revoke four oil permits from a unit of China’s Sinopec...</p>
                </article>

                {/* 4th article */}
                {/* <article className="flex flex-col h-full mb-4">
                  <header>
                    <Link className="block mb-4" to="/nigerias-portharcourt-refinery">
                      <figure className="relative h-0 pb-9/16">
                        <img className="absolute inset-0 w-full h-full object-cover" src={require('../images/nigeria-portharcourt-reniery.png')} width="352" height="198" alt="News 01" />
                      </figure>
                    </Link>
                    <Link className="hover:underline" to="/nigerias-portharcourt-refinery">
                      <h3 className="h5 font-red-hat-display mb-2"> <b>Nigeria’s Port Harcourt Refinery Receives $1.5B For Rehabilitation Works</b> </h3>
                    </Link>
                  </header>
                  <p className="text-gray-600 dark:text-gray-400 flex-grow">The current rehabilitation project is one of several that have been carried out on the refinery in the last 25 years...</p>
                </article> */}

              </div>

              {/* Load more button */}
              {/* <div className="flex justify-center mt-12 md:mt-16">
                <a className="btn-sm text-gray-300 hover:text-gray-100 bg-gray-800 flex items-center" href="#0">
                  <span>See previous articles</span>
                  <svg className="w-4 h-4 flex-shrink-0 ml-3" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path className="fill-current text-gray-500" d="M14.7 9.3l-1.4-1.4L9 12.2V0H7v12.2L2.7 7.9 1.3 9.3 8 16z" />
                  </svg>
                </a>
              </div> */}

            </div>

            

          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogList;