import React, { useState } from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory } from 'react-router-dom';
import ls from 'local-storage';
// import { Link, useHistory } from 'react-router-dom';
import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import Footer from '../partials/Footer';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
// import Dashboard from './Dashboard';
// import PricingTables from '../partials/PricingTables';
// import SubscriptionPlan from '../partials/SubscriptionPlan';

function Subscribe() {
  let newUserEmail = ls.get('email');
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState(newUserEmail);
  const [state, setState] = useState('');
  const [password, setPassword] = useState('');
  const [telephone, setTelephone] = useState('');
  const [loadSpinner, setLoadSpinner] = useState(false);
  // const [error, setError] = useState('');

  

  //used for routing to dashboard after token retured
  let history = useHistory();

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      setLoadSpinner(true);
      const data = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        url: 'https://apmcnisiinitiatives.herokuapp.com/api/signup',
        data: {
          firstname,
          lastname,
          state,
          email,
          telephone,
          password,
        },
      });

      //If data return contained a token

      if (data.data.token) {
        ls.set('userDetails', data.data);
        toast('You have successfully Registered');
        history.push('/dashboard');
        // return <Dashboard userDetails={data.data} />;
      }
    } catch (err) {
      // Catch error message
      if (err.response) {
        toast(`${err.response.data}`);
        setLoadSpinner(false);
      } else {
        toast(`${err.message}`);
        setLoadSpinner(false);
      }
    }
  };
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <h1 className="h1 font-red-hat-display mb-4">
                  Get started with APMcNISI in seconds
                </h1>
                <p className="text-xl text-gray-600 dark:text-gray-400">
                  Enter valid informations below to subscribe.
                </p>
                {/* {error && <span style={{ color: 'red' }}>{error}</span>} */}
              </div>

              {/* Subscribe form */}
              <form className="max-w-xl mx-auto">
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                    <label
                      className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                      htmlFor="first-name"
                    >
                      First Name <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="first-name"
                      type="text"
                      className="form-input w-full"
                      placeholder="Enter your first name"
                      required
                      name="firstname"
                      value={firstname}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                      htmlFor="last-name"
                    >
                      Last Name <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="last-name"
                      type="text"
                      className="form-input w-full"
                      placeholder="Enter your last name"
                      required
                      name="lastname"
                      value={lastname}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <label
                      className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                      htmlFor="state"
                    >
                      State <span className="text-red-600">*</span>
                    </label>
                    <select
                      id="state"
                      className="form-select w-full"
                      required
                      name="state"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    >
                      <option>Abia</option>
                      <option>Adamawa</option>
                      <option>Akwa Ibom</option>
                      <option>Anambra</option>
                      <option>Bauchi</option>
                      <option>Bayelsa</option>
                      <option>Benue</option>
                      <option>Borno</option>
                      <option>Cross River</option>
                      <option>Delta</option>
                      <option>Ebonyi</option>
                      <option>Edo</option>
                      <option>Ekiti</option>
                      <option>Enugu</option>
                      <option>Gombe</option>
                      <option>Imo</option>
                      <option>Jigawa</option>
                      <option>Kaduna</option>
                      <option>Kano</option>
                      <option>Katsina</option>
                      <option>Kebbi</option>
                      <option>Kogi</option>
                      <option>Kwara</option>
                      <option>Lagos</option>
                      <option>Nassarawa</option>
                      <option>Niger</option>
                      <option>Ogun</option>
                      <option>Ondo</option>
                      <option>Osun</option>
                      <option>Oyo</option>
                      <option>Plateau</option>
                      <option>Rivers</option>
                      <option>Sokoto</option>
                      <option>Taraba</option>
                      <option>Yobe</option>
                      <option>Zamfara</option>
                      <option>Federal Capital Territory - Abuja</option>
                    </select>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <label
                      className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                      htmlFor="email-address"
                    >
                      Email Address <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="company"
                      type="email"
                      className="form-input w-full"
                      placeholder="Enter your email address"
                      required
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <label
                      className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                      htmlFor="phone"
                    >
                      Phone Number <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="phone"
                      type="tel"
                      className="form-input w-full"
                      placeholder="Enter your phone number"
                      required
                      name="telephone"
                      value={telephone}
                      onChange={(e) => setTelephone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <label
                      className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="phone"
                      type="password"
                      className="form-input w-full"
                      placeholder="Enter password"
                      required
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>

                {/* <PricingTables/> */}
                {/* <SubscriptionPlan/> */}
                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <button
                      className="btn text-white bg-teal-500 hover:bg-teal-400 w-full flex items-center"
                      onClick={submitForm}
                    >
                      {loadSpinner ? (
                        <Loader
                          type="TailSpin"
                          color="#FFFFFF"
                          height={20}
                          width={20}
                          timeout={3000} //3 secs
                        />
                      ) : (
                        ''
                      )}
                        <span>Subscribe</span>
                      <svg
                        className="w-3 h-3 flex-shrink-0 mt-px ml-2"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="fill-current"
                          d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div className="max-w-3xl mx-auto text-center pt-5">
                  <p
                    className="text-xl text-gray-600 dark:text-gray-400"
                    style={{ fontSize: '15px' }}
                  >
                    Already Subscribed/have an account?{' '}
                    <Link to="/signin">
                      {' '}
                      <b>Sign In</b>{' '}
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Subscribe;
