import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ls from 'local-storage';
import MUIDataTable from 'mui-datatables';

const columns = ['Depot', 'Price', 'Date'];

// const data = [
//   ['Obat', '10-04-2021', '234.00', '222.00', '221.00', '100.00'],
//   ['Nipco', '18-04-2021', '242.50', '154.00', '0.00', '0.00'],
//   ['MRS', '18-04-2021', '240.00', '154.50', '123.00', '0.00'],
// ];

const options = {
  filterType: 'checkbox',
};

export default function LPG20TonsPricesTable() {
  const [LPG20TonsPrices, setLPG20TonsPrices] = useState([]);
  let userDetails = ls.get('userDetails');
  useEffect(() => {
    const fetchData = async () => {
      const data = await axios({
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          'x-auth-token': userDetails.token,
        },
        url: 'https://apmcnisiinitiatives.herokuapp.com/api/lpg20',
      }).then((res) => res.data);

      const newData = data.lpg20.reverse().map((depot) => [
        depot.depot,
        depot.price,
        depot.date,
      ]);

      setLPG20TonsPrices(newData);
    };
    fetchData();
  }, [LPG20TonsPrices.length, userDetails.token]);
  return (
    <MUIDataTable
      title={'LPG 20Tons Prices'}
      data={LPG20TonsPrices}
      columns={columns}
      options={options}
    />
  );
}
