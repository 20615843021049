import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ls from 'local-storage';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import Footer from '../partials/Footer';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

function Signin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loadSpinner, setLoadSpinner] = useState(false);

  //used for routing to dashboard after token retured
  let history = useHistory();

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      setLoadSpinner(true);
      const data = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        url: 'https://apmcnisiinitiatives.herokuapp.com/api/signin',
        data: {
          email,
          password,
        },
      });

      
      //If data return contained a token
      if (data.data.token) {
        if(data.data.user.status === 'Deactivated'){
          toast('You account has been Deactivated, Please contact the Support Department');
          return history.push('/signin');       
        } else {
          ls.set('userDetails', data.data);
        toast('Logged in Successfull');
        return history.push('/dashboard');
        }        
      }
    } catch (err) {
      // Catch error message
      if (err.response) {
        toast(`${err.response.data}`);
        setLoadSpinner(false);
      } else {
        toast(`${err.message}`);
        setLoadSpinner(false);
      }
    }
  };
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <h1 className="h1 font-red-hat-display mb-4">Sign In</h1>
                <p className="text-xl text-gray-600 dark:text-gray-400">
                  Enter valid informations below to signin.
                </p>
              </div>

              {/* Signin form */}
              <form className="max-w-xl mx-auto">
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <label
                      className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                      htmlFor="email-address"
                    >
                      Email Address <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="company"
                      type="email"
                      className="form-input w-full"
                      placeholder="Enter your email address"
                      required
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <label
                      className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="phone"
                      type="password"
                      className="form-input w-full"
                      placeholder="Enter your password"
                      required
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <button
                      className="btn text-white bg-teal-500 hover:bg-teal-400 w-full flex items-center"
                      onClick={submitForm}
                    >
                      {loadSpinner ? (
                        <Loader
                          type="TailSpin"
                          color="#FFFFFF"
                          height={20}
                          width={20}
                          timeout={3000} //3 secs
                        />
                      ) : (
                        ''
                      )}
                      <span>Sign In</span>
                      <svg
                        className="w-3 h-3 flex-shrink-0 mt-px ml-2"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="fill-current"
                          d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="max-w-3xl mx-auto text-center pt-5">
                  <p className="text-xl text-gray-600 dark:text-gray-400" style={{ fontSize: '15px' }}> Don't have an account?
                    {' '}
                    <Link to="/subscribe">
                      {' '}
                      <b>Subscribe</b>
                      {' '}
                    </Link>
                  </p>
                  <p className="text-xl text-gray-600 dark:text-gray-400" style={{ fontSize: '15px' }}> 
                    {' '}
                    <Link to="/forgotpassword">
                      {' '}
                      <b>Forgot Password?</b>
                      {' '}
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Signin;
