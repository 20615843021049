import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import ls from 'local-storage';

const columns = ['State', 'Date', 'AGO', 'DPK', 'LPG', 'PMS'];

// const data = [
//   ['Abia', '19-04-2021', '123.00', '123.00', '123.00', '0.00'],
//   ['Borno', '19-04-2021', '123.00', '123.00', '123.00', '0.00'],
//   ['Anambra', '19-04-2021', '123.00', '123.00', '123.00', '0.00'],
// ];

const options = {
  //   filterType: 'checkbox',
};

export default function CollapsibleTable() {
  const [statePricesData, setStatePricesData] = useState([]);
  let userDetails = ls.get('userDetails');
  useEffect(() => {
    const fetchData = async () => {
      const data = await axios({
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          'x-auth-token': userDetails.token,
        },
        url: 'https://apmcnisiinitiatives.herokuapp.com/api/pricestate',
      }).then((res) => res.data);

      const newData = data.priceByState
        .reverse()
        .map((state) => [
          state.state,
          state.date.split('T')[0],
          state.AGO,
          state.DPK,
          state.LPG,
          state.PMS,
        ]);

      setStatePricesData(newData);
    };
    fetchData();
  }, [statePricesData.length, userDetails.token]);

  return (
    <MUIDataTable
      title={'State Prices'}
      data={statePricesData}
      columns={columns}
      options={options}
    />
  );
}
