import React from 'react';
// import { Link } from 'react-router-dom';
import Header from '../partials/Header';
// import PageIllustration from '../partials/PageIllustration';
import Footer from '../partials/Footer';
// import PricingTables from '../partials/PricingTables';
// import SubscriptionPlan from '../partials/SubscriptionPlan';
import MyAccountSection from '../partials/MyAccountSettings';

function MyAccount() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow pb-12 md:pb-16 mb-4">
        {/* <div className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1" aria-hidden="true"> */}
          <MyAccountSection/>
        {/* </div> */}
      </main>      

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default MyAccount;