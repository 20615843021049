import React, { useEffect, useState } from "react";
import axios from "axios";
import ls from "local-storage";
import { CChartLine } from "@coreui/react-chartjs";
import { getStyle, hexToRgba } from "@coreui/utils";

const brandSuccess = getStyle("success") || "#4dbd74";
const brandInfo = getStyle("info") || "#20a8d8";
const brandDanger = getStyle("danger") || "#f86c6b";
const brandWarning = getStyle("warning") || "#f0ad4e";

const DepotPricesChart = (attributes) => {
  
  // 
  const [depotData, setDepotData] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);
  const [data7, setData7] = useState([]);

  let userDetails = ls.get("userDetails");
  useEffect(() => {
    const fetchData = async () => {
      const data = await axios({
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          'x-auth-token': userDetails.token,
        },
        url: `https://apmcnisiinitiatives.herokuapp.com/api/depotprice/date?date=${
          attributes.startDateDepot.toISOString().split('T')[0]
        }`,
      }).then((res) => res.data);
      // console.log(data)
      let depotData = data.depotPrice.map((depot) => depot.depot);
      let data4 = data.depotPrice.map((depot) => Number(depot.AGO));
      let data5 = data.depotPrice.map((depot) => Number(depot.DPK));
      let data6 = data.depotPrice.map((depot) => Number(depot.ATK));
      let data7 = data.depotPrice.map((depot) => Number(depot.PMS));

      setData4(data4);
      setData5(data5);
      setData6(data6);
      setData7(data7);
      setDepotData(depotData);
    };
    fetchData();
  }, [depotData.length, userDetails.token, attributes.startDateDepot]);
  //

  // const random = (min, max) => {
  //   return Math.floor(Math.random() * (max - min + 1) + min);
  // };

  const defaultDatasets = (() => {
    // let elements = 37;
    // const data1 = [];
    // const data2 = [];
    // const data3 = [];
    // for (let i = 0; i <= elements; i++) {
    //   data1.push(random(50, 200));
    //   data2.push(random(80, 100));
    //   data3.push(65);
    // }
    return [
      {
        label: "AGO",
        backgroundColor: hexToRgba(brandInfo, 10),
        borderColor: brandInfo,
        pointHoverBackgroundColor: brandInfo,
        borderWidth: 1,
        data: data4,
      },
      {
        label: "DPK",
        backgroundColor: "transparent",
        borderColor: brandSuccess,
        pointHoverBackgroundColor: brandSuccess,
        borderWidth: 3,
        data: data5,
      },
      {
        label: "ATK",
        backgroundColor: "transparent",
        borderColor: brandDanger,
        pointHoverBackgroundColor: brandDanger,
        borderWidth: 2,
        borderDash: [8, 5],
        data: data6,
      },
      {
        label: "PMS",
        backgroundColor: "transparent",
        borderColor: brandWarning,
        pointHoverBackgroundColor: brandWarning,
        borderWidth: 1,
        borderDash: [8, 5],
        data: data7,
      },
    ];
  })();

  const defaultOptions = (() => {
    return {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              stepSize: Math.ceil(250 / 5),
              max: 200,
            },
            gridLines: {
              display: true,
            },
          },
        ],
      },
      elements: {
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3,
        },
      },
    };
  })();

  // render
  return (
    <CChartLine
      {...attributes}
      datasets={defaultDatasets}
      options={defaultOptions}
      labels={depotData}
      // labels={[
      //   "Mo",
      //   "Tu",
      //   "We",
      //   "Th",
      //   "Fr",
      //   "Sa",
      //   "Su",
      //   "Mo",
      //   "Tu",
      //   "We",
      //   "Th",
      //   "Fr",
      //   "Sa",
      //   "Su",
      //   "Mo",
      //   "Tu",
      //   "We",
      //   "Th",
      //   "Fr",
      //   "Sa",
      //   "Su",
      //   "Mo",
      //   "Tu",
      //   "We",
      //   "Th",
      //   "Fr",
      //   "Sa",
      //   "Su",
      // ]}
    />
  );
};

export default DepotPricesChart;
