import React from 'react';

function Services() {
  return (
    <section className="relative">
    {/* <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
    <div className="pt-32 pb-12 xxmd:pt-40 md:pb-20 py-12 md:py-20 border-t border-gray-200 dark:border-gray-800"> */}
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20 py-12 md:py-20 border-t border-gray-200 dark:border-gray-800">
          <div className="lg:flex lg:justify-between">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-6xx pt-6xx md:pb-20xx">
            <h2 className="h2 font-red-hat-display mb-4">Our Services</h2>
            <p className="text-xl text-gray-600 dark:text-gray-400">APMI is Nigeria's premiere Energy analysis and data servicing company. We provide detailed analysis of the market and market trends based on intelligent sources we have recruited over the years. Our services are tailored for our individual clients considering the peculiarity of the energy sector in Nigeria and West Africa.</p>
          </div>

        </div>
        </div>
      </div>
    </section>
  );
}

export default Services;