import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import ls from 'local-storage';

const columns = ['Depot', 'Date', 'AGO', 'DPK', 'ATK', 'PMS'];

// const data = [
//   ['Obat', '10-04-2021', '234.00', '222.00', '221.00', '100.00'],
//   ['Nipco', '18-04-2021', '242.50', '154.00', '0.00', '0.00'],
//   ['MRS', '18-04-2021', '240.00', '154.50', '123.00', '0.00'],
// ];

const options = {
  filterType: 'checkbox',
};

export default function DepotPricesTable() {
  const [depotPricesData, setDepotPricesData] = useState([]);
  let userDetails = ls.get('userDetails');
  useEffect(() => {
    const fetchData = async () => {
      const data = await axios({
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          'x-auth-token': userDetails.token,
        },
        url: 'https://apmcnisiinitiatives.herokuapp.com/api/depotprice',
      }).then((res) => res.data);

      const newData = data.depotPrice.reverse().map((depot) => [
        depot.depot,
        depot.date.split('T')[0],
        depot.AGO,
        depot.DPK,
        depot.ATK,
        depot.PMS,
      ]);

      setDepotPricesData(newData);
    };
    fetchData();
  }, [depotPricesData.length, userDetails.token]);
  return (
    <MUIDataTable
      title={'Depot Prices'}
      data={depotPricesData}
      columns={columns}
      options={options}
    />
  );
}
