import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ls from 'local-storage';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
// import { Chart } from 'react-charts';
import { Bar } from 'react-chartjs-2';
// import CurrentPlan from './CurrentPlan';
// import ls from 'local-storage';
// import CurrentPlan from './CurrentPlan';
// import { Link } from 'react-router-dom';
// import StatePricesChart from './MyChart/MyChart';
import StatePricesTable from './StatePricesTable/StatePricesTable';
import DepotPricesTable from './DepotPricesTable/DepotPricesTable';
import LPG20TonsPricesTable from './LPG20TonsPricesTable/LPG20TonsPricesTable';
import DepotRetailPricesTable from './DepotRetailPricesTable/DepotRetailPricesTable';
import StatePricesChart from './charts/StatePricesChart'
import DepotPricesChart from './charts/DepotPricesChart'
import DistrictRetailPricesChart from './charts/DistrictRetailPricesChart'
import LPG20TonsPricesChart from './charts/LPG20TonsPricesChart'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import SubscriptionPlan from './SubscriptionPlan';
// import Table from 'react-bootstrap'
// import Button from 'react-bootstrap/Button'

function MyAccountSettings({ userDetails }) {
  const [firstname, setFirstName] = useState(userDetails.user.firstname);
  const [lastname, setLastName] = useState(userDetails.user.lastname);
  const [state, setState] = useState(userDetails.user.state);
  const [telephone, setTelephone] = useState(userDetails.user.telephone);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [category, setCategory] = useState('1');
  const [rows, setRows] = useState([]);
  const [startDateDepot, setStartDateDepot] = useState(new Date());
  const [startDateState, setStartDateState] = useState(new Date());
  const [startDateDistrict, setStartDateDistrict] = useState(new Date());
  const [startDateLPG, setStartDateLPG] = useState(new Date());
  // const [year, setYear] = useState('2021');


  useEffect(() => {
    const fetchData = async () => {
      const data = await axios({
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          'x-auth-token': userDetails.token,
        },
        url: 'https://apmcnisiinitiatives.herokuapp.com/api/depotprice',
      }).then((res) => res.data);
      setRows(data.depotPrice);
    };
    fetchData();
  }, [rows.length, userDetails.token]);
  let dpk = rows.map((x) => Number(x.DPK)).filter((x) => x > 0).length;
  let ago = rows.map((x) => Number(x.AGO)).filter((x) => x > 0).length;
  let atk = rows.map((x) => Number(x.ATK)).filter((x) => x > 0).length;
  let pms = rows.map((x) => Number(x.PMS)).filter((x) => x > 0).length;

  const submitForm = async (e) => {
    try {
      e.preventDefault();
      setLoadSpinner(true);
      const data = await axios({
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          'x-auth-token': userDetails.token,
        },
        url: 'https://apmcnisiinitiatives.herokuapp.com/api/updateprofile',
        data: {
          firstname,
          lastname,
          email: userDetails.user.email,
          telephone,
          state,
        },
      });
      ls.set('userDetails', {
        token: userDetails.token,
        user: {
          firstname,
          lastname,
          email: userDetails.user.email,
          telephone,
          state,
        },
      });
      setLoadSpinner(false);
      toast(`${data.data.message}`);
    } catch (err) {
      if (err.response) {
        toast(`${err.response.data}`);
        setLoadSpinner(false);
      } else {
        toast(`${err.message}`);
        setLoadSpinner(false);
      }
    }
  };

  const submitForm1 = async (e) => {
    try {
      e.preventDefault();
      setLoadSpinner(true);
      if (newPassword === confirmPassword) {
        const data = await axios({
          method: 'put',
          headers: {
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
            'x-auth-token': userDetails.token,
          },
          url:
            'https://apmcnisiinitiatives.herokuapp.com/api/updateprofile/password',
          data: {
            email: userDetails.user.email,
            newPassword,
          },
        });
        setLoadSpinner(false);
        toast(`${data.data.message}`);
      } else {
        toast(`New Password and Confirm New Password must match`);
      }
    } catch (err) {
      if (err.response) {
        toast(`${err.response.data}`);
        setLoadSpinner(false);
      } else {
        toast(`${err.message}`);
        setLoadSpinner(false);
      }
    }
  };

  const data1 = {
    labels: ['Lowest', 'Average', 'Highest'],
    datasets: [
      {
        label: '',
        backgroundColor: [
          'rgb(234,67,54)',
          'rgb(251,188,6)',
          'rgb(65,133,244)',
        ],
        borderWidth: 2,
        data: [154.77, 153.0, 145.1, 140.0],
      },
    ],
  };

  

  return (
    <section className="relative border-txx xxborder-transparent xxdark:border-gray-800">
      {/* Background gradient */}
      <div
        classNamexx="absolute inset-0 h-128 dark:opacity-25 bg-gradient-to-b from-gray-100 to-white dark:from-gray-800 dark:to-gray-900 pointer-events-none"
        aria-hidden="true"
      ></div>

      {/* End background gradient */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20 py-12 md:py-20">
          {/* Section header */}
          {/* <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h4 className="h4 font-red-hat-display mb-4">Profile Settings</h4>
            <p className="text-xl text-gray-600 dark:text-gray-400">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat cupidatat.</p>
          </div> */}

          {/* Section content */}
          <div>
            <div className=" grid md:grid-cols-2 gap-12 lg:gap-4" style={{display: 'flex'}}>
              {/* Category buttons */}
              <div style={{paddingRightxx: '200px', width: 'fit-content'}} className="lg:col-span-1 lg:pr-16xx flex flex-wrap justify-center lg:flex-col lg:justify-start -m-1 lg:mx-0">
                {/* Profile Details */}
                <img
                  style={{ display: 'block', borderRadius: '100px' }}
                  className="absolutexx inset-0 w-fullxx h-fullxx object-cover mb-4"
                  src={require('../images/career-01.jpg').default}
                  width="60"
                  height="60"
                  alt="avatar"
                />
                <h5 className="h5 font-red-hat-display mb-4">
                  {' '}
                  <b>
                    {userDetails.user.firstname} {userDetails.user.lastname}
                  </b>{' '}
                </h5>
                <p className="text-ml text-gray-600 dark:text-gray-400 mb-4">
                  {userDetails.user.state}, <br /> Member since{' '}
                  {userDetails.user.dateRegistered
                    .split('T')[0]
                    .split('-')
                    .reverse()
                    .join('/')}
                </p>
                {/*  */}

                {/* Dashboard */}
                <button
                  className={`lg:w-full font-medium px-3 py-2 bg-white hover:bg-gray-50 shadow dark:bg-gray-800 dark:hover:bg-gray-700 transition duration-150 ease-in-out rounded flex items-center justify-center lg:justify-start m-1 lg:mx-0 ${
                    category === '1' &&
                    'bg-teal-500 hover:bg-teal-500 dark:bg-teal-600 dark:hover:bg-teal-600 dark:bg-opacity-25 dark:hover:bg-opacity-25'
                  }`}
                  onClick={() => setCategory('1')}
                >
                  <span
                    className={
                      category === '1'
                        ? 'text-white dark:text-teal-400'
                        : 'text-gray-600 dark:text-gray-300'
                    }
                  >
                    Dashboard
                  </span>
                </button>

                {/* State Prices Table */}
                <button
                  className={`lg:w-full font-medium px-3 py-2 bg-white hover:bg-gray-50 shadow dark:bg-gray-800 dark:hover:bg-gray-700 transition duration-150 ease-in-out rounded flex items-center justify-center lg:justify-start m-1 lg:mx-0 ${
                    category === '2' &&
                    'bg-teal-500 hover:bg-teal-500 dark:bg-teal-600 dark:hover:bg-teal-600 dark:bg-opacity-25 dark:hover:bg-opacity-25'
                  }`}
                  onClick={() => setCategory('2')}
                >
                  <span
                    className={
                      category === '2'
                        ? 'text-white dark:text-teal-400'
                        : 'text-gray-600 dark:text-gray-300'
                    }
                  >
                    State Prices
                  </span>
                </button>

                {/* Depot Prices Table */}
                <button
                  className={`lg:w-full font-medium px-3 py-2 bg-white hover:bg-gray-50 shadow dark:bg-gray-800 dark:hover:bg-gray-700 transition duration-150 ease-in-out rounded flex items-center justify-center lg:justify-start m-1 lg:mx-0 ${
                    category === '3' &&
                    'bg-teal-500 hover:bg-teal-500 dark:bg-teal-600 dark:hover:bg-teal-600 dark:bg-opacity-25 dark:hover:bg-opacity-25'
                  }`}
                  onClick={() => setCategory('3')}
                >
                  <span
                    className={
                      category === '3'
                        ? 'text-white dark:text-teal-400'
                        : 'text-gray-600 dark:text-gray-300'
                    }
                  >
                    Depot Prices
                  </span>
                </button>

                {/* Depot Retail Prices Table */}
                <button
                  className={`lg:w-full font-medium px-3 py-2 bg-white hover:bg-gray-50 shadow dark:bg-gray-800 dark:hover:bg-gray-700 transition duration-150 ease-in-out rounded flex items-center justify-center lg:justify-start m-1 lg:mx-0 ${
                    category === '4' &&
                    'bg-teal-500 hover:bg-teal-500 dark:bg-teal-600 dark:hover:bg-teal-600 dark:bg-opacity-25 dark:hover:bg-opacity-25'
                  }`}
                  onClick={() => setCategory('4')}
                >
                  <span
                    className={
                      category === '4'
                        ? 'text-white dark:text-teal-400'
                        : 'text-gray-600 dark:text-gray-300'
                    }
                  >
                    District Retail Prices
                  </span>
                </button>

                {/* Depot Retail Prices Table */}
                <button
                  className={`lg:w-full font-medium px-3 py-2 bg-white hover:bg-gray-50 shadow dark:bg-gray-800 dark:hover:bg-gray-700 transition duration-150 ease-in-out rounded flex items-center justify-center lg:justify-start m-1 lg:mx-0 ${
                    category === '5' &&
                    'bg-teal-500 hover:bg-teal-500 dark:bg-teal-600 dark:hover:bg-teal-600 dark:bg-opacity-25 dark:hover:bg-opacity-25'
                  }`}
                  onClick={() => setCategory('5')}
                >
                  <span
                    className={
                      category === '5'
                        ? 'text-white dark:text-teal-400'
                        : 'text-gray-600 dark:text-gray-300'
                    }
                  >
                    LPG 20Tons Prices
                  </span>
                </button>

                {/* Edit Profile */}
                <button
                  className={`lg:w-full font-medium px-3 py-2 bg-white hover:bg-gray-50 shadow dark:bg-gray-800 dark:hover:bg-gray-700 transition duration-150 ease-in-out rounded flex items-center justify-center lg:justify-start m-1 lg:mx-0 ${
                    category === '6' &&
                    'bg-teal-500 hover:bg-teal-500 dark:bg-teal-600 dark:hover:bg-teal-600 dark:bg-opacity-25 dark:hover:bg-opacity-25'
                  }`}
                  onClick={() => setCategory('6')}
                >
                  <span
                    className={
                      category === '6'
                        ? 'text-white dark:text-teal-400'
                        : 'text-gray-600 dark:text-gray-300'
                    }
                  >
                    Edit Profile
                  </span>
                </button>

                {/* Subscription */}
                {/* <button
                  className={`lg:w-full font-medium px-3 py-2 bg-white hover:bg-gray-50 shadow dark:bg-gray-800 dark:hover:bg-gray-700 transition duration-150 ease-in-out rounded flex items-center justify-center lg:justify-start m-1 lg:mx-0 ${
                    category === '3' &&
                    'bg-teal-500 hover:bg-teal-500 dark:bg-teal-600 dark:hover:bg-teal-600 dark:bg-opacity-25 dark:hover:bg-opacity-25'
                  }`}
                  onClick={() => setCategory('3')}
                >
                  <span
                    className={
                      category === '3'
                        ? 'text-white dark:text-teal-400'
                        : 'text-gray-600 dark:text-gray-300'
                    }
                  >
                    Subscription
                  </span>
                </button> */}

                {/* Billing */}
                {/* <button
                  className={`lg:w-full font-medium px-3 py-2 bg-white hover:bg-gray-50 shadow dark:bg-gray-800 dark:hover:bg-gray-700 transition duration-150 ease-in-out rounded flex items-center justify-center lg:justify-start m-1 lg:mx-0 ${
                    category === '10' &&
                    'bg-teal-500 hover:bg-teal-500 dark:bg-teal-600 dark:hover:bg-teal-600 dark:bg-opacity-25 dark:hover:bg-opacity-25'
                  }`}
                  onClick={() => setCategory('10')}
                >
                  <span
                    className={
                      category === '10'
                        ? 'text-white dark:text-teal-400'
                        : 'text-gray-600 dark:text-gray-300'
                    }
                  >
                    Billing
                  </span>
                </button> */}

                {/* Password */}
                <button
                  className={`lg:w-full font-medium px-3 py-2 bg-white hover:bg-gray-50 shadow dark:bg-gray-800 dark:hover:bg-gray-700 transition duration-150 ease-in-out rounded flex items-center justify-center lg:justify-start m-1 lg:mx-0 ${
                    category === '7' &&
                    'bg-teal-500 hover:bg-teal-500 dark:bg-teal-600 dark:hover:bg-teal-600 dark:bg-opacity-25 dark:hover:bg-opacity-25'
                  }`}
                  onClick={() => setCategory('7')}
                >
                  <span
                    className={
                      category === '7'
                        ? 'text-white dark:text-teal-400'
                        : 'text-gray-600 dark:text-gray-300'
                    }
                  >
                    Change Password
                  </span>
                </button>

                {/* Help &amp; Support */}
                {/* <button
                  className={`lg:w-full font-medium px-3 py-2 bg-white hover:bg-gray-50 shadow dark:bg-gray-800 dark:hover:bg-gray-700 transition duration-150 ease-in-out rounded flex items-center justify-center lg:justify-start m-1 lg:mx-0 ${
                    category === '5' &&
                    'bg-teal-500 hover:bg-teal-500 dark:bg-teal-600 dark:hover:bg-teal-600 dark:bg-opacity-25 dark:hover:bg-opacity-25'
                  }`}
                  onClick={() => setCategory('5')}
                >
                  <span
                    className={
                      category === '5'
                        ? 'text-white dark:text-teal-400'
                        : 'text-gray-600 dark:text-gray-300'
                    }
                  >
                    Help &amp; Support
                  </span>
                </button> */}
                {/* <button
                  className={`lg:w-full font-medium px-3 py-2 bg-white hover:bg-gray-50 shadow dark:bg-gray-800 dark:hover:bg-gray-700 transition duration-150 ease-in-out rounded flex items-center justify-center lg:justify-start m-1 lg:mx-0 ${category === '5' && 'bg-teal-500 hover:bg-teal-500 dark:bg-teal-600 dark:hover:bg-teal-600 dark:bg-opacity-25 dark:hover:bg-opacity-25'}`}
                  onClick={() => setCategory('5')}
                >
                  <svg className="w-4 h-4 flex-shrink-0 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path className={`fill-current ${category === '5' ? 'text-teal-200 dark:text-teal-400' : 'text-teal-500 dark:text-gray-400'}`} d="M11 16v-5h5V0H5v5H0v11h11zM2 7h7v7H2V7z" />
                  </svg>
                  <span className={category === '5' ? 'text-white dark:text-teal-400' : 'text-gray-600 dark:text-gray-300'}>Image Field</span>
                </button> */}
              </div>

              {/* This is the begining of side nav contents for users */}
              {/* <form className="max-w-xl mx-auto"> */}
              <div style={{paddingLeft: '-500px', width: '100%'}} className="lg:col-span-1  flex flex-wrap justify-center lg:flex-col lg:justify-start -m-1 lg:mx-0">
                {/* Dashboard Contents */}
                <div className="grid md:grid-cols-1 gap-12 mb-4">
                  <div
                    className={['1'].includes(category) ? undefined : 'hidden'}
                  >
                    {/* <SubscriptionPlan/> */}
                    <article classNamex="p-6 bg-white dark:bg-gray-800 shadow-2xl flex flex-col">
                      <header>
                        {/* <ul className="flex justify-left flex-wrapx items-centerx mb-4">
                          <li>
                            <span style={{height: '25px', fontSize: '14px', borderRadius: '40px', backgroundColor: '#77cc87'}} className="btn-sm text-white bg-teal-500 hover:bg-dark-400 ml-6"> Number of depots with products </span>
                          </li>
                        </ul> */}
                        <h6 className="h6 font-red-hat-display mb-4">
                          {/* <a href="#0"> */}
                          {/* {' '} */}
                          <button>
                            <b>Number of depots with products</b>
                          </button>
                          {/* {' '} */}
                          {/* </a> */}
                        </h6>
                      </header>
                      <div className=" grid md:grid-cols-4 gap-4 mb-4">
                        <div
                          className="text-gray-600 dark:text-gray-400 flex-grow"
                          style={{
                            // backgroundColor: '#77cc87',
                            backgroundColor: 'none',
                            // borderRadius: '500px',
                            border: '#afebba',
                            borderStyle: 'solid',
                            borderRadius: '15px',
                            padding: '20px',
                            opacity: '1.2',
                            // color: '#ffffff',
                            borderColor: '#afebba',
                          }}
                        >
                          <p style={{ fontSize: '50px' }}>
                            {' '}
                            <b>{ago}</b>{' '}
                          </p>
                          <span>
                            {' '}
                            <b>AGO</b>{' '}
                          </span>
                        </div>
                        <div
                          className="text-gray-600 dark:text-gray-400 flex-grow"
                          style={{
                            // backgroundColor: '#83c2ed',
                            backgroundColor: 'none',
                            border: '#b9e0fb',
                            borderStyle: 'solid',
                            borderRadius: '15px',
                            padding: '20px',
                            opacity: '1.2',
                            // color: '#ffffff',
                            borderColor: '#b9e0fb',
                          }}
                        >
                          <p style={{ fontSize: '50px' }}>
                            {' '}
                            <b>{dpk}</b>{' '}
                          </p>
                          <span>
                            {' '}
                            <b>DPK</b>{' '}
                          </span>
                        </div>
                        <div
                          classNamex="text-white-600 dark:text-white-400 flex-grow"
                          style={{
                            // backgroundColor: '#eeca64',
                            backgroundColor: 'none',
                            border: '#f8e2a2',
                            borderStyle: 'solid',
                            borderRadius: '15px',
                            padding: '20px',
                            opacity: '1.2',
                            // color: '#ffffff',
                            borderColor: '#f8e2a2',
                          }}
                        >
                          <p style={{ fontSize: '50px' }}>
                            {' '}
                            <b>{atk}</b>{' '}
                          </p>
                          <span>
                            {' '}
                            <b>ATK</b>{' '}
                          </span>
                        </div>
                        <div
                          className="text-gray-600 dark:text-gray-400 flex-grow"
                          style={{
                            // backgroundColor: '#ea8397',
                            backgroundColor: 'none',
                            border: '#f8a8b8',
                            borderStyle: 'solid',
                            borderRadius: '15px',
                            padding: '20px',
                            opacity: '1.2',
                            // color: '#ffffff',
                            borderColor: '#f8a8b8',
                          }}
                        >
                          <p style={{ fontSize: '50px' }}>
                            {' '}
                            <b>{pms}</b>{' '}
                          </p>
                          <span>
                            {' '}
                            <b>PMS</b>{' '}
                          </span>
                        </div>
                      </div>
                    </article>
                    <article classNamex="p-6 bg-white dark:bg-gray-800 shadow-2xl flex flex-col">
                      <div className=" grid md:grid-cols-1 gap-12 mb-4">
                        <div className="text-gray-600 dark:text-gray-400 flex-grow" style={{ border: '#f1f2f0', borderStyle: 'solid', borderRadius: '15px', padding: '20px', opacity: '1.2', borderColor: '#f1f2f0'}}>
                          <div className=" grid md:grid-cols-2 gap-4 mb-4">
                            <div className="text-gray-600 dark:text-gray-400 flex-grow">
                              <h5 className="mb-4"><b>State Prices Chart</b></h5>
                            </div>
                            <div className="text-gray-600 dark:text-gray-400 flex-grow"
                              style={{
                                // backgroundColor: '#83c2ed',
                                width: '219px',
                                float: 'right',
                                backgroundColor: 'none',
                                border: '#b9e0fb',
                                borderStyle: 'solid',
                                borderRadius: '5px',
                                // padding: '20px',
                                opacity: '1.2',
                                // color: '#ffffff',
                                borderColor: '#b9e0fb',
                                marginLeft: 'auto'
                              }}
                            >
                              <DatePicker
                                md="12"
                                style={{ widthxx: "400px", border: 'none', borderColor: '#ffffff', borderWidth: '0px' }}
                                selected={startDateState}
                                onChange={(date) => setStartDateState(date)}
                                width="400%"
                              />
                            </div>
                          </div>
                          {/* <h5 className="mb-4"><b>State Prices Chart</b></h5> */}
                          
                          <StatePricesChart startDateState={startDateState}/>
                        </div>
                      </div>
                    </article>
                    
                    <article classNamex="p-6 bg-white dark:bg-gray-800 shadow-2xl flex flex-col">
                      <div className=" grid md:grid-cols-1 gap-12 mb-4">
                        <div className="text-gray-600 dark:text-gray-400 flex-grow" style={{ border: '#f1f2f0', borderStyle: 'solid', borderRadius: '15px', padding: '20px', opacity: '1.2', borderColor: '#f1f2f0'}}>
                          <div className=" grid md:grid-cols-2 gap-4 mb-4">
                            <div className="text-gray-600 dark:text-gray-400 flex-grow">
                              <h5 className="mb-4"><b>Depot Prices Chart</b></h5>
                            </div>
                            <div className="text-gray-600 dark:text-gray-400 flex-grow"
                              style={{
                                // backgroundColor: '#83c2ed',
                                width: '219px',
                                float: 'right',
                                backgroundColor: 'none',
                                border: '#b9e0fb',
                                borderStyle: 'solid',
                                borderRadius: '5px',
                                // padding: '20px',
                                opacity: '1.2',
                                // color: '#ffffff',
                                borderColor: '#b9e0fb',
                                marginLeft: 'auto'
                              }}
                            >
                              <DatePicker
                                md="12"
                                style={{ widthxx: "400px", border: 'none', borderColor: '#ffffff', borderWidth: '0px' }}
                                selected={startDateDepot}
                                onChange={(date) => setStartDateDepot(date)}
                                width="400%"
                              />
                            </div>
                          </div>
                          
                          <DepotPricesChart startDateDepot={startDateDepot}/>
                        </div>
                      </div>
                    </article>

                    <article classNamex="p-6 bg-white dark:bg-gray-800 shadow-2xl flex flex-col">
                      <div className=" grid md:grid-cols-1 gap-12 mb-4">
                        <div className="text-gray-600 dark:text-gray-400 flex-grow" style={{ border: '#f1f2f0', borderStyle: 'solid', borderRadius: '15px', padding: '20px', opacity: '1.2', borderColor: '#f1f2f0'}}>
                          <div className=" grid md:grid-cols-2 gap-4 mb-4">
                            <div className="text-gray-600 dark:text-gray-400 flex-grow">
                              <h5 className="mb-4"><b>District Retail Prices Chart</b></h5>
                            </div>
                            <div className="text-gray-600 dark:text-gray-400 flex-grow"
                              style={{
                                // backgroundColor: '#83c2ed',
                                width: '219px',
                                float: 'right',
                                backgroundColor: 'none',
                                border: '#b9e0fb',
                                borderStyle: 'solid',
                                borderRadius: '5px',
                                // padding: '20px',
                                opacity: '1.2',
                                // color: '#ffffff',
                                borderColor: '#b9e0fb',
                                marginLeft: 'auto'
                              }}
                            >
                              <DatePicker
                                md="12"
                                style={{ widthxx: "400px", border: 'none', borderColor: '#ffffff', borderWidth: '0px' }}
                                selected={startDateDistrict}
                                onChange={(date) => setStartDateDistrict(date)}
                                width="400%"
                              />
                            </div>
                          </div>
                          
                          <DistrictRetailPricesChart startDateDistrict={startDateDistrict}/>
                        </div>
                      </div>
                    </article>
                    
                    <article classNamex="p-6 bg-white dark:bg-gray-800 shadow-2xl flex flex-col">
                      <div className=" grid md:grid-cols-1 gap-12 mb-4">
                        <div className="text-gray-600 dark:text-gray-400 flex-grow" style={{ border: '#f1f2f0', borderStyle: 'solid', borderRadius: '15px', padding: '20px', opacity: '1.2', borderColor: '#f1f2f0'}}>
                          <div className=" grid md:grid-cols-2 gap-4 mb-4">
                            <div className="text-gray-600 dark:text-gray-400 flex-grow">
                              <h5 className="mb-4"><b>LPG 20Tons Prices Chart</b></h5>
                            </div>
                            <div className="text-gray-600 dark:text-gray-400 flex-grow"
                              style={{
                                width: '219px',
                                float: 'right',
                                // backgroundColor: 'none',
                                border: '#b9e0fb',
                                borderStyle: 'solid',
                                borderRadius: '5px',
                                opacity: '1.2',
                                borderColor: '#b9e0fb',
                                marginLeft: 'auto',
                                backgroundColor: 'rgba(52, 52, 52, alpha)'
                              }}
                            >
                              <DatePicker
                                md="12"
                                style={{ widthxx: "400px", border: 'none', backgroundColor: 'rgba(52, 52, 52, alpha)', borderColor: '#ffffff', borderWidth: '0px' }}
                                selected={startDateLPG}
                                onChange={(date) => setStartDateLPG(date)}
                                width="400%"
                              />
                            </div>
                          </div>
                          
                          <LPG20TonsPricesChart startDateLPG={startDateLPG}/>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>

                {/* <div className="grid md:grid-cols-1 gap-1 mb-4"> */}
                  {/* <div style={{ width: '700px', height: '300px'}}> */}
                    {/* <Chart data={data} axes={axes} /> */}
                    
                  {/* </div> */}
                {/* </div> */}

                {/* <div className="grid md:grid-cols-1 gap-1 mb-4"> */}
                  {/* <div style={{ width: '700px', height: '500px'}}>
                    
                  </div> */}
                {/* </div> */}

                {/* <div className="grid md:grid-cols-1 gap-1 mb-4"> */}
                  {/* <div style={{ width: '700px', height: '500px'}}>
                    
                  </div> */}
                {/* </div> */}

                {/* <div className="grid md:grid-cols-1 gap-1 mb-4"> */}
                  {/* <div style={{ width: '700px', height: '300px'}}>
                    
                  </div> */}
                {/* </div> */}

                <div className={['1'].includes(category) ? undefined : 'hidden'}>
                  <Bar
                    data={data1}
                    options={{
                      title: {
                        display: true,
                        text: 'Low, Average & Highest Depot Prices',
                        fontSize: 20,
                        fontColor: 'red',
                        position: 'right',
                      },
                      legend: {
                        display: false,
                        position: 'right',
                      },
                    }}
                  />
                </div>

                <div className="grid md:grid-cols-1 gap-4">
                  <div
                    className={['2'].includes(category) ? undefined : 'hidden'}
                  >
                    <p>State Prices</p>
                    <StatePricesTable />
                  </div>
                </div>

                <div className="grid md:grid-cols-1 gap-4">
                  <div
                    className={['3'].includes(category) ? undefined : 'hidden'}
                  >
                    <h5>Depot Prices</h5>
                    <DepotPricesTable />
                  </div>
                </div>

                <div className="grid md:grid-cols-1 gap-4">
                  <div
                    className={['4'].includes(category) ? undefined : 'hidden'}
                  >
                    <DepotRetailPricesTable />
                  </div>
                </div>

                <div className="grid md:grid-cols-1 gap-4">
                  <div className={['5'].includes(category) ? undefined : 'hidden'}>
                    <LPG20TonsPricesTable />
                  </div>
                </div>

                {/* Subscription */}
                {/* <div className={['3'].includes(category) ? undefined : 'hidden'} >
                  <div className={`flex-grow ${year !== '2021' && 'hidden'}`} onClick={() => setYear('2020')} >
                    <div className="grid md:grid-cols-1 gap-4 sm:gap-6 mb-4">
                      <article className="p-6 bg-white dark:bg-gray-800 shadow-2xl flex flex-col">
                        <header className="grid md:grid-cols-3 gap-4 sm:gap-6 mb-4">
                          <h6 className="h6 font-red-hat-display mb-3xx">
                            <b style={{ fontSize: '12px', marginTop: '30px' }}>
                              YOUR PLAN
                            </b>
                          </h6>

                          <Link
                            style={{
                              width: '100px',
                              fontSize: '12px',
                              height: '30px',
                              marginLeft: '200px',
                            }}
                            to="/signin"
                            className="btn-sm text-green bg-white hover:bg-gray-50 shadow ml-60xx"
                          >
                            Cancel Plan
                          </Link>
                          <Link
                            style={{
                              width: '120px',
                              fontSize: '12px',
                              height: '30px',
                            }}
                            to="/subscribe"
                            className="btn-sm text-white bg-teal-500 hover:bg-teal-400 ml-20"
                          >
                            Upgrade Plan
                          </Link>
                        </header>
                        <div className="text-gray-600 dark:text-gray-400 flex-grow">
                          <h4 className="h4 font-red-hat-display mt-3">
                            {' '}
                            <b>{userDetails.user.plan}</b> - plan{' '}
                          </h4>
                        </div>
                      </article>
                    </div>
                    <div className="grid md:grid-cols-1 gap-4 mb-4">
                      <article className="p-6 bg-white dark:bg-gray-800 shadow-2xl flex flex-col">
                        <header>
                          <h6 className="h6 font-red-hat-display mb-4">
                            <a href="#0">
                              {' '}
                              <b>Payment Method</b>{' '}
                            </a>
                          </h6>
                        </header>
                        <div className="grid md:grid-cols-2 gap-4 mb-4">
                          <div
                            className="text-gray-600 dark:text-gray-400 flex-grow"
                            style={{
                              backgroundColor: '#ffffff',
                              border: '#f1f2f0',
                              borderStyle: 'solid',
                              borderRadius: '15px',
                              padding: '20px',
                              opacity: '1.2',
                              borderColor: '#f1f2f0',
                            }}
                          >
                            <img
                              className="inline-block mb-4"
                              src={require('../images/mastercard.svg').default}
                              width="34"
                              height="34"
                              alt="iPhone mockup"
                              aria-hidden="true"
                            />
                            <br />
                            <button>**** **** **** 3203</button>
                          </div>
                          <div
                            className="text-gray-600 dark:text-gray-400 flex-grow"
                            style={{
                              backgroundColor: '#ffffff',
                              border: '#f1f2f0',
                              borderStyle: 'solid',
                              borderRadius: '15px',
                              padding: '20px',
                              opacity: '1.2',
                              borderColor: '#f1f2f0',
                            }}
                          >
                            <img
                              className="inline-block mb-4"
                              src={require('../images/visa.svg').default}
                              width="34"
                              height="34"
                              alt="iPhone mockup"
                              aria-hidden="true"
                            />
                            <br />
                            <button>**** **** **** 3203</button>
                          </div>
                        </div>
                        <Link
                          style={{
                            width: '120px',
                            fontSize: '12px',
                            height: '30px',
                          }}
                          to="/subscribe"
                          className="mt-2 inline-flex btn-sm text-white bg-teal-500 hover:bg-teal-400 ml-20xx"
                        >
                          Add New Card
                        </Link>
                      </article>
                    </div> */}

                {/* Billing Information */}
                {/* <div className="grid md:grid-cols-1 gap-4 mb-4">
                      <article className="p-6 bg-white dark:bg-gray-800 shadow-2xl flex flex-col">
                        <header>
                          <h6 className="h6 font-red-hat-display mb-4">
                            <a href="#0">
                              {' '}
                              <b>Billing Information</b>{' '}
                            </a>
                          </h6>
                        </header>
                        <div className="grid md:grid-cols-1 gap-4 mb-4">
                          <div
                            classNamexx="text-gray-600xx dark:text-gray-400 flex-grow"
                            style={{
                              borderStyle: 'dotted',
                              color: '#5e5e5e',
                              backgroundColor: '#f1f2f0',
                              borderRadius: '15px',
                              padding: '20px',
                            }}
                          >
                            <span className="mb-4" style={{ color: '#fffff' }}>
                              {' '}
                              <b>
                                {userDetails.user.firstname}{' '}
                                {userDetails.user.lastname}
                              </b>{' '}
                            </span>
                            <br />
                            <br />
                            <span style={{ color: '#fffff', fontSize: '15px' }}>
                              <b>Address:</b> 8251 Hodkiewicz Mews, South
                              Jeniferbury, Alabama, Sweden 67460-1221
                            </span>
                            <br />
                            <br />
                            <span style={{ color: '#fffff', fontSize: '15px' }}>
                              <b>Phone:</b> +2347060460216
                            </span>
                            <br />
                            <Link
                              style={{
                                width: '120px',
                                fontSize: '12px',
                                height: '30px',
                              }}
                              to="/subscribe"
                              className="mt-2 inline-flex btn-sm text-white bg-teal-500 hover:bg-teal-400 ml-20xx"
                            >
                              Edit
                            </Link>{' '}
                            &nbsp;{' '}
                            <Link
                              style={{
                                width: '120px',
                                fontSize: '12px',
                                height: '30px',
                              }}
                              to="/subscribe"
                              className="mt-2 inline-flex btn-sm text-white bg-danger-500 hover:bg-danger-400 ml-20xx"
                            >
                              Delete
                            </Link>
                          </div>
                          <Link
                            style={{
                              width: '150px',
                              fontSize: '12px',
                              height: '30px',
                            }}
                            to="/subscribe"
                            className="mt-2 inline-flex btn-sm text-white bg-teal-500 hover:bg-teal-400 ml-20xx"
                          >
                            Add New Address
                          </Link>
                        </div>
                      </article>
                    </div>
                  </div>
                </div> */}

                {/* Profile Settings Details */}
                <div className="grid md:grid-cols-2 gap-6">
                  <div
                    className={['6'].includes(category) ? undefined : 'hidden'}
                  >
                    <div className="relative flexxx justify-centerxx items-center mr-4">
                      <div className="flex flex-wrap -mx-3 mb-5">
                        <label
                          className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                          htmlFor="first-name"
                        >
                          First Name <span className="text-red-600">*</span>
                        </label>
                        <input
                          id="first-name"
                          type="text"
                          className="form-input w-full"
                          placeholder={`${userDetails.user.firstname}`}
                          value={firstname}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={['6'].includes(category) ? undefined : 'hidden'}
                  >
                    <div className="relative flexxx justify-centerxx items-center ml-4">
                      <div className="flex flex-wrap -mx-3 mb-5">
                        <label
                          className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                          htmlFor="last-name"
                        >
                          Last Name <span className="text-red-600">*</span>
                        </label>
                        <input
                          id="last-name"
                          type="text"
                          className="form-input w-full"
                          placeholder={`${userDetails.user.lastname}`}
                          value={lastname}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={['6'].includes(category) ? undefined : 'hidden'}
                  >
                    <div className="relative flexxx justify-centerxx items-center mr-4">
                      <div className="flex flex-wrap -mx-3 mb-5">
                        <label
                          className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                          htmlFor="first-name"
                        >
                          Email Address <span className="text-red-600">*</span>
                        </label>
                        <input
                          id="first-name"
                          type="email"
                          className="form-input w-full"
                          placeholder="Enter your email address"
                          value={userDetails.user.email}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={['6'].includes(category) ? undefined : 'hidden'}
                  >
                    <div className="relative flexxx justify-centerxx items-center ml-4">
                      <div className="flex flex-wrap -mx-3 mb-5">
                        <label
                          className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                          htmlFor="last-name"
                        >
                          Phone Number <span className="text-red-600">*</span>
                        </label>
                        <input
                          id="tel"
                          type="tel"
                          className="form-input w-full"
                          placeholder={`${userDetails.user.telephone}`}
                          value={telephone}
                          onChange={(e) => setTelephone(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={['6'].includes(category) ? undefined : 'hidden'}
                  >
                    <div className="relative flexxx justify-centerxx items-center mr-4">
                      <div className="flex flex-wrap -mx-3 mb-5">
                        <label
                          className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                          htmlFor="state"
                        >
                          State <span className="text-red-600">*</span>
                        </label>
                        <select
                          id="state"
                          className="form-select w-full"
                          placeholder={`${userDetails.user.firstname}`}
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          required
                        >
                          <option>Abia</option>
                          <option>Adamawa</option>
                          <option>Akwa Ibom</option>
                          <option>Anambra</option>
                          <option>Bauchi</option>
                          <option>Bayelsa</option>
                          <option>Benue</option>
                          <option>Borno</option>
                          <option>Cross River</option>
                          <option>Delta</option>
                          <option>Ebonyi</option>
                          <option>Edo</option>
                          <option>Ekiti</option>
                          <option>Enugu</option>
                          <option>Gombe</option>
                          <option>Imo</option>
                          <option>Jigawa</option>
                          <option>Kaduna</option>
                          <option>Kano</option>
                          <option>Katsina</option>
                          <option>Kebbi</option>
                          <option>Kogi</option>
                          <option>Kwara</option>
                          <option>Lagos</option>
                          <option>Nassarawa</option>
                          <option>Niger</option>
                          <option>Ogun</option>
                          <option>Ondo</option>
                          <option>Osun</option>
                          <option>Oyo</option>
                          <option>Plateau</option>
                          <option>Rivers</option>
                          <option>Sokoto</option>
                          <option>Taraba</option>
                          <option>Yobe</option>
                          <option>Zamfara</option>
                          <option>Federal Capital Territory - Abuja</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                    className={['6'].includes(category) ? undefined : 'hidden'}
                  >
                    <div className="relative flexxx justify-centerxx items-center ml-4">
                      <div className="flex flex-wrap -mx-3 mt-6">
                        <button
                          className="btn text-white bg-teal-500 hover:bg-teal-400 w-full flex items-centerxx"
                          onClick={submitForm}
                        >
                          {loadSpinner ? (
                            <Loader
                              type="TailSpin"
                              color="#FFFFFF"
                              height={20}
                              width={20}
                              timeout={3000} //3 secs
                            />
                          ) : (
                            ''
                          )}
                          <span>Save Changes</span>
                          <svg
                            className="w-3 h-3 flex-shrink-0 mt-px ml-2"
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              className="fill-current"
                              d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Change Password */}
                <div className="grid md:grid-cols-2 gap-6">
                  <div
                    className={['7'].includes(category) ? undefined : 'hidden'}
                  >
                    <div className="relative flexxx justify-centerxx items-center mr-4">
                      <div className="flex flex-wrap -mx-3 mb-5">
                        <label
                          className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                          htmlFor="password"
                        >
                          New Password <span className="text-red-600">*</span>
                        </label>
                        <input
                          id="password"
                          type="password"
                          className="form-input w-full"
                          placeholder="Enter your new password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={['7'].includes(category) ? undefined : 'hidden'}
                  >
                    <div className="relative flexxx justify-centerxx items-center mr-4">
                      <div className="flex flex-wrap -mx-3 mb-5">
                        <label
                          className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                          htmlFor="password"
                        >
                          Confirm New Password{' '}
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          id="password"
                          type="password"
                          className="form-input w-full"
                          placeholder="Confirm your new password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={['7'].includes(category) ? undefined : 'hidden'}
                  >
                    <div className="relative flexxx justify-centerxx items-center mr-4">
                      <div className="flex flex-wrap -mx-3 mt-6">
                        <button
                          className="btn text-white bg-teal-500 hover:bg-teal-400 w-full flex items-centerxx"
                          onClick={submitForm1}
                        >
                          {loadSpinner ? (
                            <Loader
                              type="TailSpin"
                              color="#FFFFFF"
                              height={20}
                              width={20}
                              timeout={3000} //3 secs
                            />
                          ) : (
                            ''
                          )}
                          <span>Save Changes</span>
                          <svg
                            className="w-3 h-3 flex-shrink-0 mt-px ml-2"
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              className="fill-current"
                              d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className={['2', '5'].includes(category) ? undefined : 'hidden'}>
                    <div className="relative flex justify-center items-center shadow">
                      <img className="w-full" src={require('../images/video-thumb.jpg')} width="352" height="264" alt="Video thumbnail 01" />
                      <div className="absolute inset-0 flex flex-col">
                        <div className="flex-grow flex justify-center items-center">
                          <a className="hover:opacity-75 transition duration-150 ease-in-out" href="#0">
                            <img src={require('../images/play-button.svg')} width="72" height="72" alt="Play icon" />
                          </a>
                        </div>
                        <div className="w-full bottom-0 px-6 py-3 bg-white opacity-90 dark:bg-gray-900 flex justify-between items-center">
                          <a className="text-gray-900 dark:text-gray-400 font-medium" href="#0">Exploring collections</a>
                          <div className="inline-flex px-3 py-1 text-xs font-medium text-white bg-gray-900 bg-opacity-50 dark:text-teal-400 dark:bg-teal-600 dark:bg-opacity-25 rounded-full">12 Min</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={['2', '3'].includes(category) ? undefined : 'hidden'}>
                    <div className="relative flex justify-center items-center shadow">
                      <img className="w-full" src={require('../images/video-thumb.jpg')} width="352" height="264" alt="Video thumbnail 01" />
                      <div className="absolute inset-0 flex flex-col">
                        <div className="flex-grow flex justify-center items-center">
                          <a className="hover:opacity-75 transition duration-150 ease-in-out" href="#0">
                            <img src={require('../images/play-button.svg')} width="72" height="72" alt="Play icon" />
                          </a>
                        </div>
                        <div className="w-full bottom-0 px-6 py-3 bg-white opacity-90 dark:bg-gray-900 flex justify-between items-center">
                          <a className="text-gray-900 dark:text-gray-400 font-medium" href="#0">Understand hierarchy</a>
                          <div className="inline-flex px-3 py-1 text-xs font-medium text-white bg-gray-900 bg-opacity-50 dark:text-teal-400 dark:bg-teal-600 dark:bg-opacity-25 rounded-full">3 Min</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={['2', '4'].includes(category) ? undefined : 'hidden'}>
                    <div className="relative flex justify-center items-center shadow">
                      <img className="w-full" src={require('../images/video-thumb.jpg')} width="352" height="264" alt="Video thumbnail 01" />
                      <div className="absolute inset-0 flex flex-col">
                        <div className="flex-grow flex justify-center items-center">
                          <a className="hover:opacity-75 transition duration-150 ease-in-out" href="#0">
                            <img src={require('../images/play-button.svg')} width="72" height="72" alt="Play icon" />
                          </a>
                        </div>
                        <div className="w-full bottom-0 px-6 py-3 bg-white opacity-90 dark:bg-gray-900 flex justify-between items-center">
                          <a className="text-gray-900 dark:text-gray-400 font-medium" href="#0">Customisations</a>
                          <div className="inline-flex px-3 py-1 text-xs font-medium text-white bg-gray-900 bg-opacity-50 dark:text-teal-400 dark:bg-teal-600 dark:bg-opacity-25 rounded-full">11 Min</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={['3', '5'].includes(category) ? undefined : 'hidden'}>
                    <div className="relative flex justify-center items-center shadow">
                      <img className="w-full" src={require('../images/video-thumb.jpg')} width="352" height="264" alt="Video thumbnail 01" />
                      <div className="absolute inset-0 flex flex-col">
                        <div className="flex-grow flex justify-center items-center">
                          <a className="hover:opacity-75 transition duration-150 ease-in-out" href="#0">
                            <img src={require('../images/play-button.svg')} width="72" height="72" alt="Play icon" />
                          </a>
                        </div>
                        <div className="w-full bottom-0 px-6 py-3 bg-white opacity-90 dark:bg-gray-900 flex justify-between items-center">
                          <a className="text-gray-900 dark:text-gray-400 font-medium" href="#0">Image galleries</a>
                          <div className="inline-flex px-3 py-1 text-xs font-medium text-white bg-gray-900 bg-opacity-50 dark:text-teal-400 dark:bg-teal-600 dark:bg-opacity-25 rounded-full">6 Min</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={['5'].includes(category) ? undefined : 'hidden'}>
                    <div className="relative flex justify-center items-center shadow">
                      <img className="w-full" src={require('../images/video-thumb.jpg')} width="352" height="264" alt="Video thumbnail 01" />
                      <div className="absolute inset-0 flex flex-col">
                        <div className="flex-grow flex justify-center items-center">
                          <a className="hover:opacity-75 transition duration-150 ease-in-out" href="#0">
                            <img src={require('../images/play-button.svg')} width="72" height="72" alt="Play icon" />
                          </a>
                        </div>
                        <div className="w-full bottom-0 px-6 py-3 bg-white opacity-90 dark:bg-gray-900 flex justify-between items-center">
                          <a className="text-gray-900 dark:text-gray-400 font-medium" href="#0">Sorting images</a>
                          <div className="inline-flex px-3 py-1 text-xs font-medium text-white bg-gray-900 bg-opacity-50 dark:text-teal-400 dark:bg-teal-600 dark:bg-opacity-25 rounded-full">4 Min</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={['5'].includes(category) ? undefined : 'hidden'}>
                    <div className="relative flex justify-center items-center shadow">
                      <img className="w-full" src={require('../images/video-thumb.jpg')} width="352" height="264" alt="Video thumbnail 01" />
                      <div className="absolute inset-0 flex flex-col">
                        <div className="flex-grow flex justify-center items-center">
                          <a className="hover:opacity-75 transition duration-150 ease-in-out" href="#0">
                            <img src={require('../images/play-button.svg')} width="72" height="72" alt="Play icon" />
                          </a>
                        </div>
                        <div className="w-full bottom-0 px-6 py-3 bg-white opacity-90 dark:bg-gray-900 flex justify-between items-center">
                          <a className="text-gray-900 dark:text-gray-400 font-medium" href="#0">Filters</a>
                          <div className="inline-flex px-3 py-1 text-xs font-medium text-white bg-gray-900 bg-opacity-50 dark:text-teal-400 dark:bg-teal-600 dark:bg-opacity-25 rounded-full">9 Min</div>
                        </div>
                      </div>
                    </div>
                  </div> */}
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
      {/* </div> */}
    </section>
  );
}

export default MyAccountSettings;
