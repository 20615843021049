import React, { useEffect, useState } from "react";
import axios from "axios";
import ls from "local-storage";
import { CChartLine } from "@coreui/react-chartjs";
import { getStyle, hexToRgba } from "@coreui/utils";

const brandSuccess = getStyle("success") || "#4dbd74";
const brandInfo = getStyle("info") || "#20a8d8";
const brandDanger = getStyle("danger") || "#f86c6b";
const brandWarning = getStyle("warning") || "#f0ad4e";

const DistrictRetailPricesChart = (attributes) => {
  
  // 
  const [districtData, setDistrictData] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);
  const [data7, setData7] = useState([]);

  let userDetails = ls.get("userDetails");
  useEffect(() => {
    const fetchData = async () => {
      const data = await axios({
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          'x-auth-token': userDetails.token,
        },
        url: `https://apmcnisiinitiatives.herokuapp.com/api/retailprice/date?date=${
          attributes.startDateDistrict.toISOString().split('T')[0]
        }`,
      }).then((res) => res.data);
      // console.log(data)
      let districtData = data.retailPrice.map((district) => district.depot);
      let data4 = data.retailPrice.map((retail) => Number(retail.AGO));
      let data5 = data.retailPrice.map((retail) => Number(retail.DPK));
      let data6 = data.retailPrice.map((retail) => Number(retail.BITUMEN));
      let data7 = data.retailPrice.map((retail) => Number(retail.PMS));

      setData4(data4);
      setData5(data5);
      setData6(data6);
      setData7(data7);
      setDistrictData(districtData);
    };
    fetchData();
  }, [districtData.length, userDetails.token, attributes.startDateDistrict]);

  const defaultDatasets = (() => {
    
    return [
      {
        label: "AGO",
        backgroundColor: hexToRgba(brandInfo, 10),
        borderColor: brandInfo,
        pointHoverBackgroundColor: brandInfo,
        borderWidth: 1,
        data: data4,
      },
      {
        label: "DPK",
        backgroundColor: "transparent",
        borderColor: brandSuccess,
        pointHoverBackgroundColor: brandSuccess,
        borderWidth: 3,
        data: data5,
      },
      {
        label: "BITUMEN",
        backgroundColor: "transparent",
        borderColor: brandDanger,
        pointHoverBackgroundColor: brandDanger,
        borderWidth: 2,
        borderDash: [8, 5],
        data: data6,
      },
      {
        label: "PMS",
        backgroundColor: "transparent",
        borderColor: brandWarning,
        pointHoverBackgroundColor: brandWarning,
        borderWidth: 1,
        borderDash: [8, 5],
        data: data7,
      },
    ];
  })();

  const defaultOptions = (() => {
    return {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              stepSize: Math.ceil(250 / 5),
              max: 200,
            },
            gridLines: {
              display: true,
            },
          },
        ],
      },
      elements: {
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3,
        },
      },
    };
  })();

  // render
  return (
    <CChartLine
      {...attributes}
      datasets={defaultDatasets}
      options={defaultOptions}
      labels={districtData}
    />
  );
};

export default DistrictRetailPricesChart;
