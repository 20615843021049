import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ls from 'local-storage';
import MUIDataTable from 'mui-datatables';

const columns = ['District', 'Date', 'AGO', 'DPK', 'BITUMEN', 'PMS'];

// const data = [
//   ['Obat', '10-04-2021', '234.00', '222.00', '221.00', '100.00'],
//   ['Nipco', '18-04-2021', '242.50', '154.00', '0.00', '0.00'],
//   ['MRS', '18-04-2021', '240.00', '154.50', '123.00', '0.00'],
// ];

const options = {
  filterType: 'checkbox',
};

export default function DepotRetailPricesTable() {
  const [depotRetailPricesData, setDepotRetailPricesData] = useState([]);
  let userDetails = ls.get('userDetails');
  useEffect(() => {
    const fetchData = async () => {
      const data = await axios({
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
          'x-auth-token': userDetails.token,
        },
        url: 'https://apmcnisiinitiatives.herokuapp.com/api/retailprice',
      }).then((res) => res.data);

      const newData = data.retailPrice.reverse().map((district) => [
        district.depot,
        district.date.split('T')[0],
        district.AGO,
        district.DPK,
        district.BITUMEN,
        district.PMS,
      ]);
      // const columns = ['State', 'Date', 'AGO', 'DPK', 'BITUMEN', 'PMS'];

      // const data = [
      //   ['Obat', '10-04-2021', '234.00', '222.00', '221.00', '100.00'],
      //   ['Nipco', '18-04-2021', '242.50', '154.00', '0.00', '0.00'],
      //   ['MRS', '18-04-2021', '240.00', '154.50', '123.00', '0.00'],
      // ];

      setDepotRetailPricesData(newData);
    };
    fetchData();
  }, [depotRetailPricesData.length, userDetails.token]);
  return (
    <MUIDataTable
      title={'Depot Retail Prices'}
      data={depotRetailPricesData}
      columns={columns}
      options={options}
    />
  );
}
