import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import axios from 'axios';

function Cta() {
  const [email, setEmail] = useState('');
  const [loadSpinner, setLoadSpinner] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      setLoadSpinner(true);
      const data = await axios({
        method: 'post',
        url: 'https://apmcnisiinitiatives.herokuapp.com/api/subscribers',
        data: {
          email,
        },
      });
      setLoadSpinner(false);
      toast(`${data.data.message}`);
    } catch (err) {
      if (err.response) {
        toast(`${err.response.data}`);
        setLoadSpinner(false);
      } else {
        toast(`${err.message}`);
        setLoadSpinner(false);
      }
    }
  };

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* CTA box */}
        <div className="dark relative bg-gray-800 py-10 px-8 md:py-16 md:px-12">
          {/* Background illustration */}
          <div
            className="absolute inset-0 left-auto  pointer-events-none"
            aria-hidden="true"
          >
            <svg
              className="h-full"
              width="400"
              height="232"
              viewBox="0 0 400 232"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <radialGradient
                  cx="50%"
                  cy="50%"
                  fx="50%"
                  fy="50%"
                  r="39.386%"
                  id="box-gr-a"
                >
                  <stop stopColor="#667EEA" offset="0%" />
                  <stop stopColor="#667EEA" stopOpacity="0" offset="100%" />
                </radialGradient>
                <radialGradient
                  cx="50%"
                  cy="50%"
                  fx="50%"
                  fy="50%"
                  r="39.386%"
                  id="box-gr-b"
                >
                  <stop stopColor="#58a65d" offset="0%" />
                  <stop stopColor="#58a65d" stopOpacity="0" offset="100%" />
                </radialGradient>
              </defs>
              <g transform="translate(-85 -369)" fill="none" fillRule="evenodd">
                <circle
                  fillOpacity=".16"
                  fill="url(#box-gr-a)"
                  cx="413"
                  cy="688"
                  r="240"
                />
                <circle
                  fillOpacity=".24"
                  fill="url(#box-gr-b)"
                  cx="400"
                  cy="400"
                  r="400"
                />
              </g>
            </svg>
          </div>

          <div className="relative flex flex-col lg:flex-row justify-between items-center">
            {/* CTA content */}
            <div className="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left lg:w-1/2">
              <h3 className="h3 font-red-hat-display text-gray-100">
                Subscribe to our newsletter
              </h3>
            </div>

            {/* CTA form */}
            <div className="w-full lg:w-1/2">
              <button
                className="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:max-w-none"
                
              >
                <input
                  type="email"
                  className="form-input w-full mb-2 sm:mb-0 sm:mr-2"
                  placeholder="Email Address"
                  aria-label="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <a
                  className="btn text-white bg-teal-500 hover:bg-teal-400 flex-shrink-0"
                  href="#0" onClick={submitForm}
                >
                  {loadSpinner ? (
                    <Loader
                      type="TailSpin"
                      color="#FFFFFF"
                      height={20}
                      width={20}
                      timeout={3000} //3 secs
                    />
                  ) : (
                    ''
                  )}
                  Subscribe
                </a>
              </button>
              {/* Success message */}
              {/* <p className="text-center lg:text-left lg:absolute mt-2 opacity-75 text-sm">Thanks for subscribing!</p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cta;
