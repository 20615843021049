import React from 'react';
import ls from 'local-storage';
// import { Link } from 'react-router-dom';
import Header from '../partials/Header';
// import { Line, Bar } from 'react-chartjs-2';
// import PageIllustration from '../partials/PageIllustration';
import Footer from '../partials/Footer';
// import PricingTables from '../partials/PricingTables';
// import SubscriptionPlan from '../partials/SubscriptionPlan';
import MyAccountSection from '../partials/MyAccountSettings';


function Dashboard() {
  let userDetails = ls.get('userDetails');

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow pb-12 md:pb-16 mb-4">
        <MyAccountSection userDetails={userDetails} />
      </main>
     

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Dashboard;
